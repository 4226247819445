import { useEffect, useRef } from 'react';

import { UTM_SOURCE_ACCESSTRADE } from '@/const/cookie';
import { styled } from '@/stitches.config';
import { IVehicleType } from '@/types/services/contentstack';

declare const window: any;

export const GTM_ID = process?.env?.NEXT_PUBLIC_GTM_ID || '';

export const isDebug = (asPath: string) => {
  return asPath.includes('gtm_debug');
};

function isDataLayerAvailable() {
  return typeof window.dataLayer !== 'undefined';
}

interface IDealerSearch {
  province: string;
  district: string;
  search: string;
  vehicleType: IVehicleType;
  showroom: boolean;
  service: boolean;
  bodyPaint: boolean;
  changeTire: boolean;
  overhaul: boolean;
}

export function trackDealerSearch(params: IDealerSearch) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({ event: 'dealer-search', ...params });
}

interface IMaintenanceSearch {
  category: string;
  model: string;
  year: string;
}
export function trackMaintenanceSearch(params: IMaintenanceSearch) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({ event: 'maintenance-search', ...params });
}

interface IMaintenanceSearchLCV {
  model: string;
  engine: string;
  mileage: string;
}

export function trackMaintenanceSearchLCV(params: IMaintenanceSearchLCV) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({ event: 'maintenance-search', ...params });
}

interface IServiceMaintenanceListSearch {
  modelSubCategory?: string;
  modelClass?: string;
  year?: string;
}
export function trackServiceMaintenanceListSearch(
  params: IServiceMaintenanceListSearch
) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({
    event: 'service-maintenance-list-search',
    ...params,
  });
}

interface IServiceMaintenanceListSearchLCV {
  model: string;
  engine: string;
  mileage: string;
}
export function trackServiceMaintenanceListSearchLCV(
  params: IServiceMaintenanceListSearchLCV
) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({
    event: 'service-maintenance-list-search',
    ...params,
  });
}
interface ILeadInterest {
  district?: string;
  city?: string;
  dealerName: string;
  id: string;
  content_ids: string;
  model?: string;
  leadId?: string;
}
export function formatModelForFacebookTracking(value: string) {
  switch (value) {
    case 'pickup':
      return 'รถปิกอัพ';
    case 'ppv':
      return 'รถอเนกประสงค์';
  }
  return 'รถบรรทุก';
}
export function trackFBLeadInterest(params: ILeadInterest) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({
    event: 'submit-crp-register-interest',
    ...params,
  });
}

export function trackFBLeadFinancial(params: ILeadInterest) {
  if (!isDataLayerAvailable()) {
    return;
  }
  window.dataLayer.push({
    event: 'submit-crp-register',
    ...params,
  });
}
interface ILeadFinancialInitiated {
  content_ids: string;
}
export function trackFBLeadFinancialInitiated(params: ILeadFinancialInitiated) {
  if (!isDataLayerAvailable()) {
    return;
  }
  window.dataLayer.push({
    event: 'submit-crp-register-initiated',
    ...params,
  });
}

export function trackFBDealerSearch(params: {
  city: string;
  district: string;
}) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({
    event: 'dealer-submit',
    ...params,
  });
}

interface IProductListFilter {
  modelClass?: string;
  modelSubCategory?: string;
  businessType?: string;
  segmentType?: string;
  bodyType?: string;
}

export function trackProductListFilter(params: IProductListFilter) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({ event: 'product-list-filter', ...params });
}

interface IProductListCompare {
  modelGroup?: string[];
  modelClass?: string;
  modelSubCategory?: string;
  businessType?: string;
  segmentType?: string;
  bodyType?: string;
}

export function trackProductListCompare(params: IProductListCompare) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({ event: 'product-list-compare', ...params });
}

interface IProductCompareOption {
  model_class?: string;
  content_type?: string;
  content_ids?: string;
  drivetrain?: string;
  price?: string;
  color?: string;
}

export function trackProductCompareOption(params: IProductCompareOption) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({ event: 'product-compare-option', ...params });
}

export function trackContentHubTag(params: { value: string }) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({ event: 'contenthub-tag', ...params });
}

export function trackMasterOfAllRoadTag(params: { value: string }) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({ event: 'master-of-all-road-tag', ...params });
}
interface IProductAdasVideo {
  adasVideo?: string;
}

export function trackProductAdasVideo(params: IProductAdasVideo) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({ event: 'product-adas-video-count', ...params });
}

interface ILCVCalculatorTracking {
  id: string;
  type?: string;
  drivetrain?: string;
  color?: string;
  preferred_price_range?: string;
  interest_rate?: string;
  period?: string;
}
export function trackLCVCalculator(params: ILCVCalculatorTracking) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({
    event: 'calculator-submit',
    content_ids: params.id,
    content_type: params.type,
    drivetrain: params.drivetrain,
    color: params.color,
    preferred_price_range: params.preferred_price_range,
    interest_rate: params.interest_rate,
    period: params.period,
  });
}

interface IContentHub {
  tags: string[];
}

interface IMasterOfAllRoad {
  tags: string[];
}

export function trackContentHub(params: IContentHub) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({
    event: 'contenthub-tags',
    content_ids: params.tags,
  });
}

export function trackMasterOfAllRoad(params: IMasterOfAllRoad) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({
    event: 'master-of-all-road-tags',
    content_ids: params.tags,
  });
}

interface IOwnerManual {
  vehicleType: string;
  year: string;
  name: string;
  url: string;
}

export function trackOwnerManual(params: IOwnerManual) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({ event: 'owner-manual-download', ...params });
}

interface IFormSubmission {
  formId: string;
  formName: string;
}

export function trackFormInitiated(params: IFormSubmission) {
  if (!isDataLayerAvailable()) {
    return;
  }
  window.dataLayer.push({
    event: 'form-initiated',
    ...params,
  });
}

export function trackFormSuccess(params: IFormSubmission) {
  if (!isDataLayerAvailable()) {
    return;
  }
  window.dataLayer.push({
    event: 'form-success',
    ...params,
  });
}

export function getLeadIDForAcessTrade(data: any) {
  let leadId = '';
  if (
    window._utm_source === UTM_SOURCE_ACCESSTRADE &&
    data &&
    data.length > 0
  ) {
    leadId = data[0].leadId;
  }

  return leadId;
}

interface ITrackABTesting {
  experiment: string;
  variant: string;
}

export function trackABTesting(params: ITrackABTesting) {
  if (!isDataLayerAvailable()) {
    return;
  }
  window.dataLayer.push({
    event: 'ab-testing',
    ...params,
  });
}

interface ITrackRegisterStepSearchDealer {
  search?: string;
}

export function trackRegisterStepSearchDealer(
  params: ITrackRegisterStepSearchDealer
) {
  if (!isDataLayerAvailable()) {
    return;
  }
  window.dataLayer.push({
    event: 'register-step-search-dealer',
    ...params,
  });
}

export const ImpressionView = styled('div', {
  position: 'absolute',
  inset: 0,
  pointerEvents: 'none',
  zIndex: -1,
});
interface IImpression {
  value: string;
}
export const useImpression = (
  params: IImpression = {
    value: '',
  }
) => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          observer.unobserve(sectionRef.current);
          if (isDataLayerAvailable()) {
            window.dataLayer.push({
              event: 'section-impression',
              value: params.value,
            });
          }
        }
      },
      { rootMargin: '0px 0px -50% 0px' }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return sectionRef;
};
